import { facebook, instagram, shieldTick, support, truckFast, twitter } from "../assets/icons";
import { bigShoe1, bigShoe2, bigShoe3, customer1, customer2, shoe4, shoe5, shoe6, shoe7 } from "../assets/images";

export const navLinks = [
    { href: "#home", label: "Home" },
    { href: "#about-us", label: "Nosotros" },
    { href: "#products", label: "Productos" },
    { href: "https://wa.link/w9t55b", label: "Contactanos" },
];

export const shoes = [
    {
        thumbnail: bigShoe1,
        bigShoe: bigShoe1,
    },
    {
        thumbnail: bigShoe2,
        bigShoe: bigShoe2,
    },
    {
        thumbnail: bigShoe3,
        bigShoe: bigShoe3,
    },
];

export const statistics = [
    { value: '+50', label: 'Diseños' },
    { value: '+100', label: 'Colores' },
    { value: '+10k', label: 'Clientes' },
];

export const products = [
    {
        imgURL: shoe4,
        name: "Black Out Premium",
        stars: 4.8
    },
    {
        imgURL: shoe5,
        name: "Sun Screen Premium",
        stars: 4.6
    },
    {
        imgURL: shoe6,
        name: "Screen Nacional",
        stars: 4.5
    },
    {
        imgURL: shoe7,
        name: "Black Out Nacional",
        stars: 4.5
    },
];

export const services = [
    
    {
        imgURL: shieldTick,
        label: "Fabricación",
        subtext: "Fabricamos cortinas standard o a medida mediante impresiones en HD."
    },
    {
        imgURL: support,
        label: "Distribución",
        subtext: "Venta de cortinas al por mayor y al por menor."
    },
    {
        imgURL: truckFast,
        label: "Envios e Instalación",
        subtext: "Ofrecemos envíos a todo el país a través de expresos e intalación de las cortinas dentro de CABA y GBA."
    }
];

export const reviews = [
    {
        imgURL: customer1,
        customerName: 'Juan Martinez',
        rating: 4.9,
        feedback: "Excelente atencion y la calidad del producto superó mis expectativas. Muy recomendable."
    },
    {
        imgURL: customer2,
        customerName: 'Josefina Etcheverry',
        rating: 4.6,
        feedback: "El producto no sólo cumplió mis expectativas, sino que las superó. Sin duda, volveré a comprarles."
    }
];


export const footerLinks = [
    {
        title: "Productos",
        links: [
            { name: "Black Out Premium", link: "/" },
            { name: "Sun Screen Premium", link: "/" },
            { name: "Screen Nacional", link: "/" },
            { name: "Black Out Nacional", link: "/" },
        ],
    },
    {
        title: "Help",
        links: [
            { name: "Nosotros", link: "/" },
            { name: "FAQs", link: "/" },
            { name: "Como funciona", link: "/" },
            { name: "Política de privacidad", link: "/" },
            { name: "Política de pagos", link: "/" },
        ],
    },
    {
        title: "Contactanos",
        links: [
            { name: "+54 9 11 2388 2728", link: "https://wa.link/w9t55b" },
            { name: "mundodecortinas19@gmail.com", link: "#" },
        ],
    },
];

export const socialMedia = [
    { src: instagram, alt: "instagram logo", link: "https://www.instagram.com/mundodecortinas?igsh=MXJhYzhqcjN4d3R1OQ==" },
];