import './Locales.css';
import TarjetaUbi from '../TarjetaUbi/TarjetaUbi';

function Locales() {
    return(
        <section id="contact-us" className="sectionLocales max-container">
            <h3 className="font-palanquin text-center text-4xl font-bold">Como
            <span className="text-coral-red"> Encontrarnos </span> 
            </h3>
            <div className="contenedor_produ1">
                <div className="container text-center">
                    <div className="row contenedor_produ1">
                      <TarjetaUbi className='col mapa' Sucursal={'Fabrica'} mapa={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3275.436815664466!2d-58.41767372446516!3d-34.82010826895811!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcd3407d842339%3A0xedc6fd91e961e68b!2sPedro%20Echag%C3%BCe%20673%2C%20Burzaco%2C%20Provincia%20de%20Buenos%20Aires!5e0!3m2!1ses!2sar!4v1717356201399!5m2!1ses!2sar"} />
                      <TarjetaUbi className='col mapa' Sucursal={'Deposito'} Telefono={'11-2468-5542'} mapa={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3285.098506622925!2d-58.71634962447774!3d-34.576373956054205!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcbdc1dd4dbb99%3A0x4a4731c2a84e3fb2!2sMontevideo%203721%2C%20B1661FYX%20Bella%20Vista%2C%20Provincia%20de%20Buenos%20Aires!5e0!3m2!1ses!2sar!4v1723677805456!5m2!1ses!2sar"} />
                    </div>
                  </div>
            </div>
        </section>
    )
}

export default Locales