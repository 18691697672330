import  { useState } from 'react';
import { shoes, statistics } from "../constants";
import ShoeCard from "../components/ShoeCard";
import Button from "../components/Button";
import { bigShoe1 } from "../assets/images";
import { arrowRight } from "../assets/icons";

const Hero = () => {
  const [bigShoeImg, setBigShoeImg] = useState(bigShoe1);

  return (
    <section
      id='home'
      className='w-full flex note:flex-row flex-col justify-center min-h-screen gap-10 max-container max-sm:pt-20'
    >
      <div className='pl-10 relative note:w-2/5 flex flex-col justify-center h-screen items-start w-full  max-note:padding-x mt-28'>
        <p className='text-xl font-montserrat text-coral-red'>
          Nueva Colección
        </p>

        <h1 className='mt-10 font-palanquin text-[72px] max-sm:leading-[82px] font-bold'>
          <span className='note:bg-white text-8xl max-sm:text-[72px] note:whitespace-nowrap relative z-10 pr-10'>
            Zona de 
          </span>
          <br />
          
          <span className='text-coral-red inline-block ml-3 mt-3 text-[106px] max-sm:text-[72px] '>Cortinas</span> 
        </h1>
        <p className='font-montserrat text-slate-gray leading-8 mt-6 mb-14 sm:max-w-sm text-xl max-[400px]:mb-1'>
          Fabricamos cortinas de alta calidad para clientes minoristas y mayoristas. Nos especializamos en ofrecer una amplia gama de productos que combinan estilo, funcionalidad y durabilidad. 
        </p>

        
          <a href="https://wa.link/w9t55b"><Button label='Comprar ahora' iconURL={arrowRight}  /></a>
        

        <div className='flex justify-start items-start flex-wrap w-full mt-20 gap-16'>
          {statistics.map((stat, index) => (
            <div key={index}>
              <p className='text-4xl font-palanquin font-bold'>{stat.value}</p>
              <p className='leading-7 font-montserrat text-slate-gray'>
                {stat.label}
              </p>
            </div>
          ))}
        </div>
      </div>

      <div className='relative flex-1 max-phone:mt-32 flex justify-center items-center note:min-h-screen max-xl:py-16 bg-primary bg-hero bg-cover bg-center'>
        <img
          src={bigShoeImg}
          alt='shoe colletion'
          width={610}
          height={502}
          className='object-contain relative z-10'
        />

        <div className='flex sm:gap-6 gap-4 absolute -bottom-[5%] sm:left-[10%] max-sm:px-6'>
          {shoes.map((image, index) => (
            <div key={index}>
              <ShoeCard
                index={index}
                imgURL={image}
                changeBigShoeImage={(shoe) => setBigShoeImg(shoe)}
                bigShoeImg={bigShoeImg}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Hero;