import { FaWhatsapp } from "react-icons/fa6";

import "./contactFixed.css";

const ContactFixed = () => {
    return (
        <div className="fixed right-5 bottom-5 bg-[#25D366] rounded-full p-4 contactFixed z-50">
            <a
                className="text-white text-4xl hover:text-5xl transition-all duration-100 ease-out"
                href="https://wa.link/tb0ie3"
            >
                <FaWhatsapp />
            </a>
        </div>
    );
};

export default ContactFixed;
