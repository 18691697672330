import { CustomerReviews, Footer, Hero, PopularProducts, Services, SpecialOffer, Subscribe, SuperQuality } from "./sections";
import Locales from "./Locales/Locales";
import Nav from "./components/Nav";
import ContactFixed from "./ContactFixed/ContactFixed";

const App = () => (
  <main className="relative">
    <Nav />
    <ContactFixed />
    <section className="xl:padding-l wide:padding-r padding-b">
      <Hero />
    </section>
    <section className="padding">
      <PopularProducts />
    </section>
    <section className="padding">
      <SuperQuality />
    </section>
    <section className="padding-x py-10">
      <Services />
    </section>
    <section className="bg-pale-blue padding">
      <Locales />
    </section>
    <section className="padding bg-black padding-x padding-t pb-8">
      <Footer />
    </section>
  </main>
)

export default App;