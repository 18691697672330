import'./TarjetaUbi.css';

const TarjetaUbi = ({ Sucursal, Telefono, Direccion, mapa}) => {
    return(
        <div className='ConetenedorContacto'>
            <h3 className="mt-5 font-palanquin text-3xl leading-normal font-bold">{Sucursal}</h3>
            <div>
                <h4 className="mt-5 font-palanquin text-3xl leading-normal font-bold">{Telefono}</h4>
            </div>
            <iframe className='maps' src={mapa}></iframe>
        </div>
    )
}

export default TarjetaUbi